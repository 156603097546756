.upload-box-sharepoint{
    font-size: 16px;
    background: white;
    float: right;
    margin-right: auto;
    border-radius: 10px;
    box-shadow: 2px 2px 10px black;
    width: 20%;
    outline: none;
    display: none !important;
}

.boton-upload-sharepoint{
    font-size: 16px;
    margin-top: 50px;
    margin-left: 40%;
    width: 20%;
    border-radius: 5px;
    background: #EA7600;
    outline: none;
    color: white;
    padding: 15px;
    font-weight: bold;
    border-color: #EA7600;
    cursor: pointer;
    display: none !important;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    height: 40px;
    background-color: rgb(255, 255, 255);
}

.divcompletoinput{
    width: 45%;
    float: right;
}

.divinoputfile{
    width: 87%;
    float: left;
}

.divbotonfile{
    width: 13%;
    float: right;
}

.divcompletoinput-pdf{
    width: 55%;
    margin-left: auto;
    margin-right: auto;
}

.divinoputfile-pdf{
    width: 92%;
    float: left;
}

.divbotonfile-pdf{
    width: 8%;
    float: right;
}


.containersharepoint {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc; 
    margin-left: auto;
    margin-right: auto;  
    min-height: 100%;
    height: auto !important;
}

