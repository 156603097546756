.divizq-modalint{
    float:left;
    width: 40%;
    height: 70%;
    margin-left: 5%;
    margin-bottom: 20px;
}

.divder-modalint{
    float:right;
    width: 40%;
    height: 70%;
    margin-right: 5%;
    margin-bottom: 20px;
}

.divizq-modalser{
    float:left;
    width: 70%;
    height: 70%;
    margin-bottom: 20px;
}

.divder-modalser{
    float:right;
    width: 30%;
    height: 70%;
    margin-bottom: 20px;
}

.divizq-botonesmodal{    
    float:left;
    width: 50%;
    height: 100%;
    margin-left: auto;
    margin-bottom: 10px;
}

.divder-botonesmodal{
    float:right;
    width: 50%;
    height: 100%;
    margin-left: auto;
    margin-bottom: 10px;
}

.divbotonmodal{
    width: 20%;
    margin-left: auto;
    margin-right: auto;
}

.headermodal{
    float:left;
    width: 100%;
    height: 10%;
    margin-left: auto;
    margin-bottom: 25px;
}

.titulomodal{
    float:left;
    width: 90%;
    height: 4%;
    margin-left: auto; 
    margin-bottom: 30px;   
}

.titulo-modalser{ 
    width: 30%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
}

.textotitulo-modal{
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    width: 100%;
}

.subtitulo-modal{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px; 
}

.subtitulo-modalser{    
    width: 250px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;    
    border-radius: 5px;  
    
}

.bodymodal{
    float:left;
    width: 100%;
    height: 70%;
    margin-left: auto; 
    margin-bottom: 35px;    
}

.footermodal{
    float:left;
    width: 100%;
    height: 10%; 
    margin-left: auto;
}

.footermodal-int{
    float:left;
    width: 100%;
    height: 10%; 
    margin-left: auto;
}

.divbotonmodal-int{
    width: 40%;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
}

.filtromodal-modalser{    
    width: 80%;
    height: 20%;
    margin-left: auto;
    margin-right: auto;    
}

.MuiInputLabel-outlined{
    z-index: 0 !important;
}

.boton-eliminar{
    width: 30px;
    height: 30px;
    background-color: #C70039;
    color: white;
    font-size: 15px;
    border-radius: 5px;  
    border-color: #900C3F;
    cursor: pointer;
    
}
.boton-eliminar:hover{
    background: #FF5733;
}

.subtitulo-modalser-respaldo{    
    width: 250px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    background-color: #85929E;
    border-radius: 5px;  
    border-color: #99A3A4;
}

.divbotonmodalreemplazo{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}