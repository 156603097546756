.divizq-reporte{
    float:left;
    width: 25%;
    height: 110%;
    margin-bottom: 20px;
    background: #efefef;
    border: 1px solid #cacaca;
    border-radius: 5px;
}

.divcartas{
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.divder-reporte{
    float:right;
    width: 70%;
    height: 100%;
    margin-bottom: 20px;
}


.divizq-secreporte{
    float:left;
    width: 30%;
    height: 100%;
    margin-bottom: 20px;
}

.divmid-secreporte{
    float:left;
    width: 30%;
    height: 100%;
    margin-bottom: 20px;
}

.divder-secreporte{
    float:left;
    width: 30%;
    height: 100%;
    margin-bottom: 20px;
}

.divseccion{
    width: 70%;
    height: 100%;
    border: 2px solid #696158;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    margin-top: 10px;
}

.divseccion-total{
    width: 70%;
    height: 100%;
    border: 2px solid #696158;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    margin-top: -6px;
}

.divseccion-titulo{
    width: 100%;
    background-color: #696158;    
    border: 2px solid #696158;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
}

.reporte-titulosec{
    text-align: center;
    width: 100%;
    font-size: 25px;
    color: white;
    background-color: #696158; 
}

.reporte-titulosec-total{
    text-align: center;
    width: 100%;
    font-size: 25px;
    color: black;
}

.divgeneral-reporte{
    width: 100%;
    height: 100%;
    margin-top: 50px;
}