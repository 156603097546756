.div-selector{
    
    width:70%;
    height: 20%;  
    background-color: #EBE9EF;
    border: 1px solid #ccc; 
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
}

.div-general-base{
    
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
}


.div-selector-options{
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
}

.divizq-general{
    float:left;
    width: 35%;
    height: 100%;
    margin-bottom: 20px;
}

.divder-general{
    float:right;
    width: 60%;
    height: 100%;
    margin-bottom: 20px;
}

.container-general {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc;   
    margin-left: auto;
    margin-right: auto;
}
