.containerclonacion {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc; 
    margin-left: auto;
    margin-right: auto;  
    height: auto !important;
}

.bootstrap-input-filtro-clonacion{
    width:100%;
    height: 50px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    outline:none;
    padding: 8px;
    font-size: inherit;
    text-align: center; 
}
  
.bootstrap-input-filtro-clonacion:focus{
    border:1px solid #2684FF !important;
    box-shadow: 0px 0px 0px 1px #2684FF !important;
}

.bootstrap-input-filtro-clonacion:hover{
    border:1px solid #2684FF !important;
    box-shadow: 0px 0px 0px 1px #2684FF !important;
}
