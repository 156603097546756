.divizq-mapp{
    float:left;
    width: 50%;
    height: 100%;
    margin-bottom: 20px;
}

.divder-mapp{
    float:right;
    width: 50%;
    height: 100%;
    margin-bottom: 20px;
}

.divizq-mapp-modal{
    float:left;
    width: 50%;
    height: 100%;
    margin-bottom: 20px;
}

.divder-mapp-modal{
    float:right;
    width: 50%;
    height: 100%;
    margin-bottom: 20px;
}

.containermaestro {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
    margin-left: 20px;
    margin-right: auto;
}

.upload-box-maestro{
    font-size: 16px;
    background: white;
    margin-left: 20%;
    margin-right: auto;
    border-radius: 20px;
    box-shadow: 2px 2px 10px black;
    width: 60%;
    outline: none;
}

.modal-modificacion{
    width: 80%;
    height: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}