.tooltip {
    position: relative;
    display: inline-block;
    color: #6b6bff;   
    font-weight: bold; 
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 180px;
    background-color: rgb(255, 255, 255);
    color: #696158;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    border: 1px solid #696158;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}
  
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltipboton {
    position: relative;
    display: inline-block;
    color: rgb(255, 255, 255);  
}

.tooltipboton .tooltiptextboton {
    visibility: hidden;
    width: auto;
    background-color: rgb(255, 255, 255);
    color: #696158;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    border: 1px solid #696158;
    font-size: medium;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltipboton:hover .tooltiptextboton {
    visibility: visible;
}