.containerfile{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 10%;
}

.upload-box{
    font-size: 16px;
    background: white;
    margin-left: 30%;
    margin-right: auto;
    border-radius: 20px;
    box-shadow: 2px 2px 10px black;
    width: 40%;
    outline: none;
}

.boton-formato{
    width: 50px;
    height: 45px;
    background-color: #098524;
    color: white;
    font-size: 20px;
    border-radius: 5px;  
    border-color: #098524;
    cursor: pointer;
    margin-left: 20px;
}
.boton-formato:hover{
    background: #1CA539;
}

.boton-actualizar{
    width: 50px;
    height: 45px;
    background-color: #EA7600;
    color: white;
    font-size: 20px;
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    float: right;
}
.boton-actualizar:hover{
    background: #ffa348;
}

.boton-upload{
    font-size: 16px;
    margin-top: 50px;
    margin-left: 40%;
    width: 20%;
    border-radius: 5px;
    background: #EA7600;
    outline: none;
    color: white;
    padding: 15px;
    font-weight: bold;
    border-color: #EA7600;
    cursor: pointer;
}
.boton-upload:hover{
    background: #ffa348;
}

::-webkit-file-upload-button{
    color: white;
    background:  #EA7600;
    padding: 20px;
    border: none;
    border-radius: 20px;
    box-shadow: 1px 0 1px 1px #6b4559;
    outline: none;
}

::-webkit-file-upload-button:hover{
    background: #ffa348;
}
