.divizq-eq{
    float:left;
    width: 48%;
    
    
}

.divder-eq{
    float:right;
    width: 48%;
     
}

.divizq-eq-prin{
    float:left;
    width: 45%;
    height: 100%;
    
}

.divder-eq-prin{
    float:right;
    width: 55%;
    height: 100%;   
}

.divizq-eq-boton{
    float:left;
    width: 25%;
    height: 100%;
    
}

.divder-eq-boton{
    float:left;
    width: 25%;
    height: 100%;   
}

.divboton-eq-boton{    
    width: 90%;   
    margin-left: auto;
    margin-right: auto;    
}

.bootstrap-input-busqueda{
    width:80%;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    outline:none;
    padding: 8px;
}

.bootstrap-input-busqueda-disabled{
    width:80%;
    border-radius: 5px;
    font-weight: bold;
    border: 1px solid #ccc;
    outline:none;
    padding: 8px;
    background-color: #F1F1F1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}
  
.bootstrap-input-busqueda:focus{
    border:1px solid #2684FF !important;
    box-shadow: 0px 0px 0px 1px #2684FF !important;
}

.bootstrap-input-busqueda:hover{
    border:1px solid #2684FF !important;
    box-shadow: 0px 0px 0px 1px #2684FF !important;
}

.boton-buscarusuario{
    width: 40px;
    height: 35px;
    background-color: #EA7600;
    color: white;
    font-size: 20px;
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    float: right;
}
.boton-buscarusuario:hover{
    background: #ffa348;
}

.boton-sccm{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 35px;
    background-color: #EA7600;
    color: white;
    font-size: 20px;
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;    
}
.boton-sccm:hover{
    background: #ffa348;
}

.divimagen{
    background-image: url('bosque.jpg');
    width: 100%;
    height: 100%;
}

.bootstrap-input-disable-eq{
    width:100%;
    border-radius: 5px;
    font-weight: bold;
    border: 1px solid #ccc;
    outline:none;
    padding: 8px;
    background-color: #F1F1F1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.col-35 {
    float: left;
    width: 35%;
    margin-top: 6px;
}

.col-45-equipo {
    float: left;
    width: 45%;
    margin-top: 6px;
}

.col-55 {
    float: left;
    width: 55%;
    margin-top: 6px;
}

.col-25-equipo {
    float: left;
    width: 25%;
    margin-top: 6px;
}

.col-75-equipo {
    float: left;
    width: 75%;
    margin-top: 6px;
}

.col-45 {
    float: left;
    width: 35%;
    margin-top: 6px;
}

.col-50 {
    float: left;
    width: 35%;
    margin-top: 6px;
}

.containerotros{
    width: 100%;
    background-color: #EBE9EF;
    height: 400px;
    margin-top: 50px;
}

.containerequipo-der{
    width: 100%;
    background-color: #EBE9EF;    
    margin-top: 50px;
}

.containerequipoprin-der{
    float: right;
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc;
    width: 100%;
}

.containerequipoprin-izq{
    float: left;
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc;
    width: 95%;
}

.posicionabsoluta{
    position: absolute;
}

.info-equiposccm{
    float: left;
    width: 100%;
}

.info-equipoav{
    float: left;
    width: 100%;
}

.info-equipoub{
    float: left;
    width: 100%;
}

.headerform-izq-esc{
    float:left;
    width: 25%;
    height: 100%;    
}

.headerform-mid-esc{
    float:left;
    width: 45%;
    height: 100%;    
}

.headerform-der-esc{
    float:right;
    width: 30%;
    height: 100%;    
}