.divizq-cuenta{
    float:left;
    width: 47%;
    height: 100%;
    margin-bottom: 20px;
}

.divder-cuenta{
    float:right;
    width: 53%;
    height: 100%;
    margin-bottom: 20px;
}