body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.selector-color{
  color: rgb(126, 123, 123);
}

.container-login {
	position: relative !important;
	padding: 10px;
	height: auto;
}

.botonMS{
  font-family: 'Segoe UI Regular', sans-serif !important;
  font-weight: 600;
  width: 250px;
  height: 55px;
  background-color: #ffffff;   
  margin: 50px auto 0 auto;
  display: block!important;
  border-width: thin;
  border-color: #8c8c8c;
  
}

.divlogin{
  width: 50%;  
  margin-left: auto;
  margin-right: auto;
}

.titlelogin{
  margin-top: 4px;
}

.col-50-left {
  float: left;
  width: 50%;
  margin-top: 6px;
}

.col-50-rigth {
  float: left;
  width: 50%;
  margin-top: 6px;
}

.login {
	width: 400px;
	height: 230px !important;
	box-shadow: 0 0 5px #bbb;
	border-radius: 25px;
	position: absolute;
	top: 120px; bottom: 0;
	left: 0; right: 0;
	margin: 0 auto;
	overflow: hidden;
}

.login header h1 {
	margin: 0 0 0 30px;
	display: block;
}
.login p {
	padding-top: 30px!important;
	font-size: 16px;
}
.login p, .form-wrapper {
  padding: 0 30px;
}

