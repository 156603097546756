.div-traducciones-base{
    
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
}


.div-selector-options{
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
}

.divizq-traducciones{
    float:left;
    width: 33%;
    height: 100%;
    margin-bottom: 20px;
}

.divder-traducciones{
    float:right;
    width: 65%;
    height: 100%;
    margin-bottom: 20px;
}

.container-traducciones {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc;   
    margin-left: auto;
    margin-right: auto;
}

.container-traducciones-modal {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc;
    width: 100%;   
    margin-left: auto;
    margin-right: auto;
}

.modal-modificacion-traducciones{
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.col-20{
    width: 20%;
    float: left;
    margin-top: 6px;
}

.col-80{
    width: 80%;
    float: left;
    margin-top: 6px;
}

.boton-form-traduccion{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #EA7600;
    color: white;
    font-size: 20px;
    padding: 2px 8px;
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
}

.boton-salir-traduccion{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #b3b3b3;
    color: white;
    font-size: 20px;
    padding: 2px 8px;
    border-radius: 5px;  
    border-color: #aeaeae;
    cursor: pointer;
}