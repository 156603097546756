.modalimpresion{
    width: 100%;
    background-color: #696158;
}

.modalimpresion-header{
    width: 90%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.modalimpresion-header-izq{
    width: 50%;
    float: left;
}

.modalimpresion-header-der{
    width: 50%;    
    float: right;
}

.modalimpresion-body{
    width: 100%;
}

.modalimpresion-footer{
    width: 100%;
}

.lineaform{
    width: 100%;
}

.lineaform-label{
    float: left;
    width: 30%;
}

.lineaform-value{
    float: left;
    width: 70%;
}

.labelimpresion{
    color: #6b6053;
}

.valueimpresion{
    color: #4b4034;
    font-weight: bold;
}

.formularioimpresion{
    width: 90%;
    margin-top: 6%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px
}

.formularioimpresion-header{
    width: 100%;
    color: #696158;
}

.formularioimpresion-footer{
    width: 100%;    
}

.formularioimpresion-footer-1{
    width: 33%;    
    float: left;
}

.formularioimpresion-footer-2{
    width: 33%;  
    float: left;  
}

.formularioimpresion-footer-3{
    width: 33%;   
    float: left; 
}

hr.firma {
    border-top: 1px dashed #696158;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

hr.separadorimpresion {
    border-top: 1px dashed #696158;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.labelfirma-footer-1{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.labelfirma-footer-2{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.labelfirma-footer-3{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.labelfirma{
    display: block;
    text-align: center;
    line-height: 150%;
}