.divinfo-usuario{
    width: 100%;
}

.divinfo-usuario-izq{
    float:left;
    width: 45%;
}

.divinfo-usuario-der{
    float:right;
    width: 45%;
}

.containerinfo-usuario {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc; 
    height: auto;
    position: relative;
    float: right;
    width: 100%;    
}

.titulo-permiso{
    text-align: center;
    width: 100%;
}

.pemisos-usuario{
    width: 100%;
}

.pemiso-30{
    float:left;
    width: 30%;
    margin-top: 30px;
}

.pemiso-20{
    float:left;
    width: 23%;
    margin-top: 30px;
}

.permiso-espacio{
    --background-color:black;
    padding-top: 44px;
}

.permiso-boton{
    margin-top: 30px;
    width: 100%;
}

.container-permiso{
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc;      
}