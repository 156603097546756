.divizqappper {
    width: 60%;
    margin-left: 5%;
    margin-right: auto;
    float: left;
}

.divderappper {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    min-width: 30%;
    float: right;
}

.containerappper {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc;   
    height: 59vh;
}

.containerfomrnotapper {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc;   
}

.bootstrap-input-appser{
    width:100%;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    outline:none;
    padding: 8px;
}
  
.bootstrap-input-appser:focus{
    border:1px solid #2684FF !important;
    box-shadow: 0px 0px 0px 1px #2684FF !important;
}

.bootstrap-input-appser:hover{
    border:1px solid #2684FF !important;
    box-shadow: 0px 0px 0px 1px #2684FF !important;
}

.bootstrap-input-appser-error{
    width:100%;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    border-color: #f90000;
    outline:none;
    padding: 8px;
}
  
.bootstrap-input-appser-error:focus{
    border:1px solid #2684FF !important;
    box-shadow: 0px 0px 0px 1px #2684FF !important;
}

.bootstrap-input-appser-error:hover{
    border:1px solid #2684FF !important;
    box-shadow: 0px 0px 0px 1px #2684FF !important;
}