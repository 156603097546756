.divizq-serv{
    float:left;
    width: 50%;
    height: 100%;
    margin-bottom: 20px;
}

.divder-serv{
    float:right;
    width: 50%;
    height: 100%;
    margin-bottom: 20px;
}

.boton-from{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #EA7600;
    color: white;
    font-size: 20px;
    padding: 2px 8px;
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
}

.boton-from:hover{    
    background-color: #ffa549;    
}

.label-form{
    width:20%;
}

.divcontenedor{
    width: 90%;
}

.bootstrap-input{
    width:100%;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    outline:none;
    padding: 8px;
}
  
.bootstrap-input:focus{
    border:1px solid #56b4ef;
    box-shadow: 0px 0px 3px 1px #c8def0;
}

.bootstrap-input-invalid{
    width:100%;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    outline:none;
    padding: 8px;
    border-color: #f90000;
}

.bootstrap-input-invalid:focus{
    border:1px solid #56b4ef;
    box-shadow: 0px 0px 3px 1px #c8def0;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

* {
    box-sizing: border-box;
}

.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
    width: 95%;
}

.container-servidor {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc;   
}

.col-25-servidor {
    float: left;
    width: 25%;
    margin-top: 6px;
}

.col-75-servidor {
    float: left;
    width: 75%;
    margin-top: 6px;
}

.col-ubicacion-nom {
    float: left;
    width: 35%;
    margin-top: 6px;
}

.col-ubicacion-campo {
    float: left;
    width: 65%;
    margin-top: 6px;
}

.col-doble-servidor-nom {
    float: left;
    width: 62.5%;
    margin-top: 6px;
}

.col-doble-servidor-campo {
    float: left;
    width: 27.5%;
    margin-top: 6px;
}

.col-doble-servidor-nom-update {
    float: left;
    width: 78%;
    margin-top: 6px;
}

.col-doble-servidor-campo-update {
    float: left;
    width: 17.5%;
    margin-top: 6px;
}


.col-der-servidor-doble {
    float: left;
    width: 40%;
}

.col-izq-servidor-doble {
    float: left;
    width: 60%;
}

.col-der-servidor-doble-update {
    float: left;
    width: 45%;
}

.col-izq-servidor-doble-update  {
    float: left;
    width: 55%;
}




.col-25 {
    float: left;
    width: 35%;
    margin-top: 6px;
}

.col-75 {
    float: left;
    width: 65%;
    margin-top: 6px;
}


.col-100 {
    float: left;
    width: 100%;
    margin-top: 6px;
    margin-left: auto;
    margin-right: auto;
}
  label {
    /*ypadding: 12px 12px 12px 0;*/
    display: inline-block;
  }

  @media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
      width: 100%;
      margin-top: 0;
    }
  }

.subtitulo-box{
    
    
}

.subtitulo{
    width: 50%;    
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;    
    text-align: center;
    font-family: 'Roboto Medium', sans-serif;
    font-weight:bold;
}