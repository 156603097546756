.containersala {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc; 
    height: 300px;  
}

.containersala-up {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc; 
    height: 420px;  
}

.containerups {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc; 
    height: 450px;  
    margin-top: 10px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.containerups-up-r {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc; 
    position: relative;
    height: auto;  
    margin-top: 10px;
    width: 45%;
    float: right;
}

.containerups-up-l {
    float: left;
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc; 
    position: relative;
    height: auto;  
    margin-top: 10px;
    width: 54%;

}

.containerups-up-l-c {
    float: right;
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc; 
    position: relative;
    height: auto;  
    margin-top: 10px;
    width: 75%;
    margin-left: auto;
    margin-right: 13%;

}

.divizq-sa-boton{
    float:left;
    width: 50%;
    height: 100%;
}

.divder-sa-boton{
    float:left;
    width: 50%;
    height: 100%;   
}

.divinfosala{
    width: 100%;
}

.divups{
    width: 100%;
    margin-top: 10px;
}

.divinfosala-izq{
    float:left;
    width: 45%;
}

.divinfosala-der{
    float:right;
    width: 45%;
}

.divinfosala-izqup{
    float:left;
    width: 50%;
    height: 100%;
}

.divinfosala-derup{
    float:right;
    width: 48%;
    height: 100%;
}

.divups-izq{
    float:left;
    width: 47%;
    padding-right: 5%;
    border-right: 2px solid #ccc;
}

.divups-mid{
    float:left;
    width: 30%;
    margin-left: 40px;
}

.divups-der{
    float:right;
    width: 48%;    
}

.divups-sep{
    float:left;
    width: 5%;
}

.vl {
    border-right: 2px solid #ccc;
    height: 300px;
    margin-left: 10px;
    float:right;
}

.col-50 {
    float: left;
    width: 50%;
    margin-top: 6px;
}

.col-60 {
    float: left;
    width: 60%;
    margin-top: 6px;
}

.col-40 {
    float: left;
    width: 40%;
    margin-top: 6px;
}

.boton-agregarservidores{
    width: 50px;
    height: 40px;
    background-color: #EA7600;
    color: white;
    font-size: 20px;
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    float: right;
}
.boton-agregarservidores:hover{
    background: #ffa348;
}