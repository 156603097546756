.busqueda-input{
    width:100%;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    outline:none;
    padding: 8px;
}
  
.busqueda-input:focus{
    border:1px solid #56b4ef;
    box-shadow: 0px 0px 3px 1px #c8def0;
}

.containerbitacora {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc; 
    margin-left: auto;
    margin-right: auto;  
    height: auto !important;
    min-height: 450px;
}