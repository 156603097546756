.divizq-app{
    float:left;
    width: 45%;
    height: 100%;
    margin-bottom: 20px;
}

.divder-app{
    float:right;
    width: 55%;
    height: 100%;
    margin-bottom: 20px;
}

.boton-from{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #EA7600;
    color: white;
    font-size: 20px;
    padding: 2px 8px;
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
}

.boton-from:hover{    
    background-color: #ffa549;    
}

.label-form{
    width:20%;
}

.divcontenedor{
    width: 90%;
}

.bootstrap-input{
    width:100%;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    outline:none;
    padding: 8px;
}
  
.bootstrap-input:focus{
    border:1px solid #2684FF !important;
    box-shadow: 0px 0px 0px 1px #2684FF !important;
}

.bootstrap-input:hover{
    border:1px solid #2684FF !important;
    box-shadow: 0px 0px 0px 1px #2684FF !important;
}

.bootstrap-input-filtro{
    width:100%;
    height: 50px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    outline:none;
    padding: 8px;
    font-size: inherit;
}
  
.bootstrap-input-filtro:focus{
    border:1px solid #2684FF !important;
    box-shadow: 0px 0px 0px 1px #2684FF !important;
}

.bootstrap-input-filtro:hover{
    border:1px solid #2684FF !important;
    box-shadow: 0px 0px 0px 1px #2684FF !important;
}

.bootstrap-input-disable{
    width:100%;
    border-radius: 5px;
    font-weight: bold;
    border: 1px solid #ccc;
    outline:none;
    padding: 8px;
    background-color: #F1F1F1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

* {
    box-sizing: border-box;
}

.container {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc;   
}

.containerappder {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc;
    float: right;
    width: 100%;   
    margin-bottom: 10px;
}

.col-25 {
    float: left;
    width: 35%;
    margin-top: 6px;
}

.col-75 {
    float: left;
    width: 65%;
    margin-top: 6px;
}



.col-100 {
    float: left;
    width: 100%;
    margin-top: 6px;
    margin-left: auto;
    margin-right: auto;
}
label {
    /*ypadding: 12px 12px 12px 0;*/
    display: inline-block;
}

.headerformulario{
    margin-top: 20px;
    width: 100%;
    height: 80px;
}

.headerform-izq{
    float:left;
    width: 20%;
    height: 100%;    
}

.headerform-mid{
    float:left;
    width: 60%;
    height: 100%;    
}

.headerform-der{
    float:right;
    width: 20%;
    height: 100%;    
}

.divtituloform{
    width: 35%;
    height: 80%; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
}

.tituloformulario{
    font-size: 25px;
    font-weight: bold;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    width: max-content;
}

@media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
      width: 100%;
      margin-top: 0;
    }
  }