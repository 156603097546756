.titulonot {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.divizqnot {
    width: 60%;
    margin-left: 10%;
    margin-right: auto;
    float: left;
}

.divdernot {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    min-width: 30%;
    float: right;
}

.divbotonnot {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.containernot {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc;   
}

.containerfomrnot {
    border-radius: 5px;
    background-color: #EBE9EF;
    padding: 20px;
    border: 1px solid #ccc;   
}