.home,
.servidor,
.menu,
.reports,
.products,
.team,
.reports {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.nav-imagen{
  margin-left: 30px;
}

.imagen-arauco{
  margin-top: 0px;
  padding-right: 10px;
}

.divimagenarauco{
  display: inline-block;
  border-right: 2px solid white;
  padding-top: 5px
}

.divtitulobanerpalabra{
  display: inline-block;
  margin-left: 5px;
}

.titulobanner{
  color:#ffffff;
  font-weight: lighter;
  font-size: 35px;    
  display:inline-block;
  margin-left: 5px;
}

.titulobanner-palabra{
  color:#ffffff;
  font-size: 28px;  
  font-family: 'Trebuchet MS', sans-serif !important;
  display:inline-block;
  margin-left: 5px;  
}

.alignleft {
	float: left !important;
}

.alignright {
	float: right !important;;
}

.swal-modal .swal-text {
  text-align: center;
}